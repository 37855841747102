<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72.001"
    height="80"
    viewBox="0 0 72.001 80"
  >
    <g transform="translate(-664 -458)">
      <path
        class="a"
        d="M-508.5,2003.862v32.275a7.713,7.713,0,0,0,3.9,6.691l28.2,16.137a7.863,7.863,0,0,0,7.8,0l28.2-16.137a7.713,7.713,0,0,0,3.9-6.691v-32.275a7.709,7.709,0,0,0-3.9-6.69l-28.2-16.138a7.859,7.859,0,0,0-7.8,0l-28.2,16.138A7.709,7.709,0,0,0-508.5,2003.862Z"
        transform="translate(1172.5 -1522)"
      />
      <path
        class="b"
        d="M15277-3254h0v-80a7.88,7.88,0,0,1,3.9,1.035l28.2,16.137a7.734,7.734,0,0,1,3.9,6.691v32.274a7.735,7.735,0,0,1-3.9,6.691l-28.2,16.137A7.885,7.885,0,0,1,15277-3254Z"
        transform="translate(-14577 3792)"
      />
      <line class="c" x2="27.5" transform="translate(686.5 514.25)" />
      <path
        class="d"
        d="M-471.726,1988l-15.91,15.91-7.955-7.955-5.3,5.3,7.955,7.955,2.652,2.651,2.651,2.651,21.213-21.213Z"
        transform="translate(1183.908 -1510.001)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FeatureSignature",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #0af;
}
.b {
  fill: #2d323c;
  stroke: rgba(0, 0, 0, 0);
  opacity: 0.147;
}
.b,
.c {
  stroke-miterlimit: 10;
}
.c {
  fill: none;
  stroke: #fff;
  stroke-width: 4px;
}
.d {
  fill: #fff;
}
</style>