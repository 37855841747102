<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="80"
    viewBox="0 0 65 80"
  >
    <g transform="translate(-348 -2040)">
      <path
        class="a"
        d="M365.5-118S333-132.286,333-163.714v-22.857s18.958-2.857,32.5-11.429c13.542,8.571,32.5,11.429,32.5,11.429v22.857C398-132.286,365.5-118,365.5-118Z"
        transform="translate(15 2238)"
      />
      <path
        class="b"
        d="M-3218.5,107h0c-.032-.014-.208-.093-.5-.232V27.313c.164-.1.327-.2.5-.313a66.372,66.372,0,0,0,10.791,5.4,101.766,101.766,0,0,0,10.537,3.527,105.775,105.775,0,0,0,11.172,2.5V61.286a43.2,43.2,0,0,1-5.078,20.4,53.67,53.67,0,0,1-11.172,14.241,69.335,69.335,0,0,1-11.172,8.348A50.644,50.644,0,0,1-3218.5,107Z"
        transform="translate(3599 2013)"
      />
      <path
        class="c"
        d="M-516.726,1990l-15.91,15.91-7.955-7.955-5.3,5.3,7.955,7.954,2.651,2.652,2.652,2.652,21.213-21.213Z"
        transform="translate(908.894 75)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FeatureVerifier",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #00d098;
}
.b {
  fill: #2d323c;
  opacity: 0.147;
}
.c {
  fill: #fff;
}
</style>