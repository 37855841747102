<template>
  <main class="home">
    <div
      class="wrapper"
      :id="'event' + eventIndex"
      v-for="(event, eventIndex) in tour.events"
      :key="eventIndex"
      :class="{ error: erronousSections.includes('event' + eventIndex) }"
    >
      <section
        class="event-header"
        :id="'event' + (eventIndex + 1)"
        @click="
          $store.dispatch('toggleEventSectionExpansion', {
            property: 'expanded',
            value: !event.utility.expanded,
            eventIndex,
          })
        "
      >
        <h4>Add event</h4>
      </section>
      <slide-up-down :active="event.utility.expanded" :duration="500">
        <section class="details" :key="'e' + eventIndex">
          <div>
            <span>
              <h5>Event name</h5>
              <DefaultTextInput
                :value="event.name"
                @change="updateName($event, eventIndex)"
                :maxlength="50"
                placeholder="Add event name"
              />
              <h5 class="location">Event location</h5>
              <div class="set-location" v-if="event.city != null">
                {{ event.city.name + ", " + event.city.country }}
                <svg
                  @click="removeCityFromEvent(eventIndex)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="remove-button"
                >
                  <g transform="translate(-320 -377)">
                    <g transform="translate(320 377)">
                      <path
                        class="a"
                        d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
                      />
                    </g>
                    <line class="b" x2="10" transform="translate(327 389)" />
                  </g>
                </svg>
              </div>
              <div
                class="find-location"
                v-else
                :class="{ expanded: locationSearchResults.length > 0 }"
              >
                <input
                  type="text"
                  autocomplete="new-password"
                  maxlength="50"
                  placeholder="Add event city and country"
                  v-if="event.city == null"
                  v-model="locationSearch"
                  @blur="locationSearch = ''"
                  @keydown="debouncedCitySearch()"
                />
                <transition-group tag="ul" name="slide-fade">
                  <li
                    v-for="location in locationSearchResults"
                    :key="location.id"
                    @click.stop="selectCity(location, eventIndex)"
                  >
                    {{ location.name }}, {{ location.country }}
                  </li>
                </transition-group>
              </div>
            </span>
            <span>
              <div class="rounds">
                <h5>Rounds</h5>
                <div
                  class="item"
                  v-for="n in 8"
                  :key="n"
                  :class="{ active: n == event.rounds.length }"
                  @click="
                    triggerRoundAdjustmentModal(
                      eventIndex,
                      event.rounds.length,
                      n
                    )
                  "
                >
                  {{ n }}
                </div>
              </div>
              <div class="pools-selector">
                <h5>Pools</h5>
                <div
                  class="item"
                  v-for="n in 5"
                  :key="n"
                  :class="{
                    none: n == 1,
                    active: event.rounds[0].pools.length == n,
                  }"
                  @click="
                    triggerPoolAdjustmentModal(
                      eventIndex,
                      event.rounds[0].pools.length,
                      n
                    )
                  "
                >
                  <template v-if="n == 1"> None </template>
                  <template v-else>
                    {{ n }}
                  </template>
                </div>
              </div>
            </span>
          </div>
        </section>

        <template v-for="(round, roundIndex) in event.rounds">
          <section
            class="pools"
            v-for="(pool, poolIndex) in round.pools"
            :key="roundIndex + 'p' + poolIndex"
          >
            <div
              class="wrapper"
              :class="{ error: erronousSections.includes('error' + poolIndex) }"
            >
              <header v-if="round.pools.length == 1">
                <h4>Round {{ roundIndex + 1 }}</h4>
              </header>
              <header v-else>
                <h4>
                  Round {{ roundIndex + 1 }} - Pool
                  {{ $store.state.poolConversion[poolIndex] }}
                </h4>
              </header>
              <div class="startwhenwrapper">
                <div class="starting-format">
                  <h5>Starting format</h5>
                  <DefaultRadioButton
                    :groupname="
                      eventIndex +
                      'event' +
                      roundIndex +
                      'starting-format' +
                      poolIndex
                    "
                    value="SHOTGUN"
                    :options="{ title: 'Shotgun', value: pool.startMethod }"
                    @change="
                      $store.dispatch('updatePoolStartMethod', {
                        eventIndex: eventIndex,
                        roundIndex: roundIndex,
                        poolIndex: poolIndex,
                        startMethod: 'SHOTGUN',
                      })
                    "
                  />
                  <DefaultRadioButton
                    :groupname="
                      eventIndex +
                      'event' +
                      roundIndex +
                      'starting-format' +
                      poolIndex
                    "
                    value="TEETIME"
                    :options="{ title: 'Teetime', value: pool.startMethod }"
                    @change="
                      $store.dispatch('updatePoolStartMethod', {
                        eventIndex: eventIndex,
                        roundIndex: roundIndex,
                        poolIndex: poolIndex,
                        startMethod: 'TEETIME',
                      })
                    "
                  />
                </div>
                <div class="when">
                  <h5>When</h5>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="poolDateTimeOptions"
                    :dateTime="pool.date"
                    @update="
                      updatePoolDateTime(
                        eventIndex,
                        roundIndex,
                        poolIndex,
                        $event
                      )
                    "
                  />
                </div>
              </div>
              <div class="where">
                <h5>Where</h5>
                <StandardSmallSolidButton
                  v-if="pool.layout !== null"
                  class="small-button"
                  :fluid="true"
                  @click="
                    toggleVisibleCourseSelectors(
                      'selector' +
                        'e' +
                        eventIndex +
                        'r' +
                        roundIndex +
                        'p' +
                        poolIndex
                    )
                  "
                  title="Edit"
                />
                <TheBuilderLayoutPicker
                  :pool="pool"
                  :edit="
                    pool.layout == null ||
                    visibleCourseSelectors.includes(
                      'selector' +
                        'e' +
                        eventIndex +
                        'r' +
                        roundIndex +
                        'p' +
                        poolIndex
                    )
                  "
                  @apply-layout="
                    applyLayout($event, eventIndex, roundIndex, poolIndex)
                  "
                />
              </div>
            </div>
          </section>
        </template>
        <div
          class="settings-header"
          @click="
            $store.dispatch('toggleEventSectionExpansion', {
              property: 'settingsExpanded',
              value: !event.utility.settingsExpanded,
              eventIndex,
            })
          "
        >
          <h4>Settings <span>(optional)</span></h4>

          <Chevron2
            :direction="event.utility.settingsExpanded ? 'up' : 'down'"
          />
        </div>
        <slide-up-down :active="event.utility.settingsExpanded" :duration="500">
          <section class="misc-settings">
            <div class="features">
              <div class="verifier">
                <FeatureVerifier class="feature-icon" />
                <h5>The Verifier™</h5>

                <DefaultToggle v-model="tourSettings.verifier" />
              </div>
              <div class="signature">
                <FeatureSignature class="feature-icon" />
                <h5>The Signature™</h5>

                <DefaultToggle v-model="tourSettings.signature" />
              </div>
            </div>
            <div class="max-players">
              <h5>Max players</h5>
              <ValueSelector
                :values="maxSpotsOptions(event).values"
                :colors="maxSpotsOptions(event).colors"
                :title="''"
                @updatedValue="
                  $store.dispatch('updateEventStringValue', {
                    value: $event.value,
                    property: 'maxSpots',
                    eventIndex: eventIndex,
                  })
                "
              />
            </div>
            <div class="group-size">
              <h5>Group size</h5>
              <div
                class="item"
                v-for="n in 5"
                :key="n"
                :class="{ active: n == event.groupSize }"
                @click="
                  $store.dispatch('updateEventStringValue', {
                    value: n,
                    property: 'groupSize',
                    eventIndex: eventIndex,
                  })
                "
              >
                {{ n }}
              </div>
            </div>
            <div class="wildcards">
              <h5>Wildcards</h5>

              <ValueSelector
                :values="wildcardOptions(event).values"
                :colors="wildcardOptions(event).colors"
                :title="''"
                @updatedValue="
                  $store.dispatch('updateEventStringValue', {
                    value: $event.value,
                    property: 'maxWildcards',
                    eventIndex: eventIndex,
                  })
                "
              />
            </div>
            <div class="registration-open">
              <div class="sub-section left">
                <h5>Registration opening date</h5>
                <TjingDateTimePicker
                  class="datepicker"
                  :canBeCleared="true"
                  :readOnly="false"
                  :options="registrationOpensTime"
                  :dateTime="event.registrationOpenAt"
                  @update="
                    updateEventTimeField(
                      $event,
                      eventIndex,
                      'registrationOpenAt'
                    )
                  "
                />
              </div>
              <div class="sub-section auto-open">
                <div class="header">
                  <h6>Automatically open</h6>
                  <DefaultToggle
                    :value="event.autoOpenRegistration"
                    :disabled="event.registrationOpenAt == null"
                    @input="
                      $store.dispatch('updateBoolOnEvent', {
                        property: 'autoOpenRegistration',
                        eventIndex: eventIndex,
                        value: $event,
                      })
                    "
                  />
                </div>

                <p>
                  When enabled Tjing will open registration for you based on the
                  registration opening date you’ve set.
                </p>
              </div>
            </div>
            <div class="waitinglist-type">
              <h5>Waiting list type</h5>
              <div class="content">
                <DefaultRadioButton
                  class="mb-4"
                  groupname="waitinglist-type"
                  :value="event.waitinglistType"
                  @change="changeWaitinglistType(eventIndex, 'DIVISION')"
                  :options="{
                    title: 'Separated divisions',
                    value: 'DIVISION',
                    description: 'Each division has its own waiting list.',
                  }"
                />
                <DefaultRadioButton
                  groupname="waitinglist-type"
                  :value="event.waitinglistType"
                  @change="changeWaitinglistType(eventIndex, 'COMBINED')"
                  :options="{
                    title: 'Combined divisions',
                    value: 'COMBINED',
                    description:
                      'All players and divisions are placed in one waiting list.',
                  }"
                />
              </div>
            </div>
            <div
              class="check-in"
              @click="
                toggleCheckInEnabled(eventIndex, !event.utility.checkInEnabled)
              "
              :class="{
                error: erronousSections.includes('check-in'),
                expanded: event.utility.checkInEnabled,
              }"
            >
              <div class="header">
                <h5>Check in</h5>
                <span
                  ><Chevron2
                    :direction="event.utility.checkInEnabled ? 'up' : 'down'"
                /></span>
              </div>
              <slide-up-down
                :active="event.utility.checkInEnabled"
                :duration="300"
              >
                <div class="open">
                  <h6>Opens</h6>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="checkInStartsTime"
                    :dateTime="event.checkInStartsAt"
                    @update="
                      updateEventTimeField(
                        $event,
                        eventIndex,
                        'checkInStartsAt'
                      )
                    "
                  />
                </div>
                <div class="closes">
                  <h6>Closes</h6>
                  <TjingDateTimePicker
                    class="datepicker"
                    :canBeCleared="true"
                    :readOnly="false"
                    :options="checkInEndsTime"
                    :dateTime="event.checkInEndsAt"
                    @update="
                      updateEventTimeField($event, eventIndex, 'checkInEndsAt')
                    "
                  />
                </div>
              </slide-up-down>
            </div>

            <div
              class="pdga-tier"
              :class="{
                expanded: event.utility.pdgaEnabled,
              }"
            >
              <div class="header">
                <h5>PDGA sanctioned</h5>
                <DefaultToggle
                  :value="event.utility.pdgaEnabled"
                  @input="togglePDGASanctioned(eventIndex, $event)"
                />
              </div>
              <slide-up-down
                :active="event.utility.pdgaEnabled"
                :duration="300"
                class="radios"
              >
                <DefaultRadioButton
                  v-for="type in pdgaEventTypes"
                  :key="type.type"
                  :groupname="'pdgatier'"
                  :value="event.pdgaType"
                  :options="{
                    title: type.name,
                    value: type.type,
                  }"
                  @change="
                    $store.dispatch('updateEventPDGAType', {
                      eventIndex: eventIndex,
                      pdgaType: type.type,
                    })
                  "
                />
              </slide-up-down>
            </div>
          </section>
        </slide-up-down>
        <div
          class="contact-details-header"
          @click="
            $store.dispatch('toggleEventSectionExpansion', {
              property: 'contactDetailsExpanded',
              value: !event.utility.contactDetailsExpanded,
              eventIndex,
            })
          "
        >
          <h4>Contact details <span>(optional)</span></h4>

          <Chevron2
            :direction="event.utility.contactDetailsExpanded ? 'up' : 'down'"
          />
        </div>
        <slide-up-down
          :active="event.utility.contactDetailsExpanded"
          :duration="500"
        >
          <div class="tournament-director">
            <h4>Tournament Director</h4>
            <DefaultTextInput
              :value="event.tournamentDirectorName"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'tournamentDirectorName',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD name"
              title="Tournament Director name"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.email"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'email',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD email"
              title="Tournament Director email"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.phone"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'phone',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add TD phone"
              title="Tournament Director phone"
              :fat="true"
            />
          </div>
          <div class="tournament-director">
            <h4>Assistant Tournament Director</h4>

            <DefaultTextInput
              :value="event.assistantTournamentDirectorName"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorName',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD name"
              title="Assistant Tournament Director name"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.assistantTournamentDirectorEmail"
              @input="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorEmail',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD email"
              title="Assistant Tournament Director email"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.assistantTournamentDirectorPhone"
              @input="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'assistantTournamentDirectorPhone',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add Assistant TD phone"
              title="Assistant Tournament Director phone"
              :fat="true"
            />
          </div>
          <div class="general">
            <h4>General details</h4>
            <DefaultTextInput
              :value="event.website"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'website',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add event website"
              title="Event website"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.pdgaEventId"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'pdgaEventId',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Paste PDGA event ID"
              title="PDGA event ID"
              :fat="true"
            />
            <DefaultTextInput
              :value="event.location"
              @change="
                $store.dispatch('updateEventStringValue', {
                  value: $event,
                  property: 'location',
                  eventIndex,
                })
              "
              :maxlength="50"
              placeholder="Add event address"
              title="Event address"
              :fat="true"
            />
            <h6>Additional details</h6>
            <textarea
              maxlength="1500"
              v-model.lazy="event.description"
              placeholder="Add additional details"
            />
          </div>
        </slide-up-down>
      </slide-up-down>
    </div>

    <section class="nav-buttons">
      <StandardBorderedButton
        title="Cancel"
        :fluid="false"
        :desktopFluidity="true"
        @click="$router.go(-1)"
      />
      <StandardSolidButton
        class="forwards"
        title="Save event"
        :fluid="false"
        :desktopFluidity="true"
        @click="validateAndProceed()"
      />
    </section>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removepoolsmodal"
      hide-close-button
    >
      <h3>Remove pools</h3>
      <p>Are you sure you want to remove pools?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removepoolsmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfPools()"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removeroundsmodal"
      hide-close-button
    >
      <h3>Remove rounds</h3>
      <p>Are you sure you want to remove rounds?</p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.removeroundsmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Remove"
          :fluid="false"
          @click="adjustNumberOfRounds()"
        />
      </div>
    </sweet-modal>

    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="saveerrormodal"
      hide-close-button
    >
      <h3>Ohh no....</h3>
      <p>
        Something went wrong...
        <br />
        <br />
        But we have exported a file you can send to our support for help
        troubleshooting what went wrong!
      </p>

      <StandardSolidButton
        class="mt-5"
        title="Close"
        :fluid="false"
        @click="$refs.saveerrormodal.close()"
      />
    </sweet-modal>
  </main>
</template>

<script>
import moment from "moment";
import { debounce } from "lodash";
import Chevron2 from "@/assets/icons/Chevron2";
import SlideUpDown from "vue-slide-up-down";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import ValueSelector from "@/components/ValueSelector";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import TjingDateTimePicker from "@/components/TjingDateTimePicker";
import TheBuilderLayoutPicker from "@/components/builder/TheBuilderLayoutPicker";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import FeatureVerifier from "@/assets/icons/FeatureVerifier";
import FeatureSignature from "@/assets/icons/FeatureSignature";

export default {
  name: "AddNewEvent",
  components: {
    StandardSolidButton,
    StandardBorderedButton,
    DefaultRadioButton,
    TjingDateTimePicker,
    DefaultToggle,
    ValueSelector,
    DefaultTextInput,
    TheBuilderLayoutPicker,
    StandardSmallSolidButton,
    Chevron2,
    SlideUpDown,
    FeatureSignature,
    FeatureVerifier,
  },
  data() {
    return {
      eventToDelete: null,
      pasteFrom: null,
      visibleCourseSelectors: [],
      locationSearch: "",
      locationSearchResults: [],
      erronousSections: [],
      roundAdjustmentValues: null,
      poolAdjustmentValues: null,
      eventId: null,
    };
  },

  computed: {
    poolDateTimeOptions() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    validCheckInTimes() {
      let valid = true;

      this.tour.events.forEach((event) => {
        if (event.checkInStartsAt == null && event.checkInEndsAt != null)
          valid = false;
        if (event.checkInEndsAt == null && event.checkInStartsAt != null)
          valid = false;
      });

      return valid;
    },
    firstPoolStartTime() {
      let time = moment().add(10, "years");

      this.tour.events.forEach((event) => {
        event.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            if (moment(pool.date).isBefore(time)) {
              time = moment(pool.date);
            }
          });
        });
      });

      return time;
    },
    checkInStartsTime() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: this.tour.events[0].checkInEndsAt,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    checkInEndsTime() {
      return {
        minDateTime: this.tour.events[0].checkInStartsAt,
        maxDateTime: moment().add(1, "years"),
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },
    registrationOpensTime() {
      return {
        minDateTime: moment().startOf("day"),
        maxDateTime: this.firstPoolStartTime,
        timeOnly: false,
        dateOnly: false,
        mode: "standard",
      };
    },

    pdgaEventTypes() {
      return this.$store.getters.tour.pdgaEventTypes;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
    tourSettings() {
      return this.$store.getters.tour.tourSettings;
    },
  },
  methods: {
    triggerPoolAdjustmentModal(eventIndex, pools, newVal) {
      this.poolAdjustmentValues = { eventIndex, pools, newVal };

      if (pools > 1 && newVal < pools) {
        this.$refs.removepoolsmodal.open();
      } else {
        this.adjustNumberOfPools();
      }
    },
    triggerRoundAdjustmentModal(eventIndex, rounds, newVal) {
      this.roundAdjustmentValues = { eventIndex, rounds, newVal };
      if (rounds > 1 && newVal < rounds) {
        this.$refs.removeroundsmodal.open();
      } else {
        this.adjustNumberOfRounds();
      }
    },
    async saveTour() {
      let clonedEvent = JSON.parse(JSON.stringify(this.tour.events[0]));
      let eventToSave = JSON.parse(JSON.stringify(this.tour.events[0]));

      eventToSave.cityId = clonedEvent.city.id;
      eventToSave.isResultSigningEnabled = this.tourSettings.signature;
      eventToSave.isVerifyMode = this.tourSettings.verifier;

      delete eventToSave.rounds;
      delete eventToSave.city;
      delete eventToSave.utility;

      try {
        let newEvent = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation CreateEvent($CreateEvent: EventInput!){
              CreateEvent(tourId:"${this.$route.params.tourId}",input:$CreateEvent){
                id
              }
            }
          `,
            variables: {
              CreateEvent: eventToSave,
            },
          },
        });

        this.eventId = newEvent.data.data.CreateEvent.id;

        for (const round of clonedEvent.rounds) {
          let newRound = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation {
                CreateRound(eventId:"${this.eventId}",asRole:TOUR_ADMIN){
                  id
                }
              }
            `,
            },
          });

          round.id = newRound.data.data.CreateRound.id;

          for (const pool of round.pools) {
            let newPool = await this.$axios({
              headers: {
                Authorization: this.$store.state.user.sessionInfo.token,
              },
              data: {
                query: `
              mutation Createpool($CreatePool: PoolInput!){
                CreatePool(roundId: "${round.id}", input: $CreatePool){
                  id
                }
              }
            `,
                variables: {
                  CreatePool: {
                    date: moment(pool.date).toISOString(),
                    layoutVersionId: pool.layout.latestVersion.id,
                  },
                },
              },
            });
          }
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to save",
          type: "error",
        });
        this.$store.dispatch("loadManagedEvents");
      }

      this.$store.dispatch("showMessage", {
        show: true,
        message: "Saved",
        type: "success",
      });

      this.$store.dispatch("loadManagedEvents");

      this.$router.push({ name: "tour-overview" });
    },
    changeWaitinglistType(eventIndex, value) {
      this.$store.dispatch("changeWaitinglistType", {
        eventIndex,
        value,
      });
    },
    toggleCheckInEnabled(eventIndex, value) {
      this.$store.dispatch("toggleCheckInEnabled", {
        eventIndex,
        value,
      });

      this.removeError("event" + eventIndex);
    },
    togglePDGASanctioned(eventIndex, value) {
      this.$store.dispatch("togglePDGASanctioned", {
        eventIndex,
        value,
      });
    },
    updatePoolDateTime(eventIndex, roundIndex, poolIndex, dateTime) {
      this.$store.dispatch("updatePoolStartTime", {
        eventIndex: eventIndex,
        roundIndex: roundIndex,
        poolIndex: poolIndex,
        date: dateTime,
      });

      this.removeError("event" + eventIndex);
    },

    removeError(error) {
      this.erronousSections = this.erronousSections.filter((e) => e != error);
    },
    updateName(data, eventIndex) {
      this.$store.dispatch("updateEventName", {
        name: data,
        eventIndex,
      });

      this.removeError("event" + eventIndex);
    },
    removeCityFromEvent(eventIndex) {
      this.$store.dispatch("removeCityFromEvent", eventIndex);
    },
    citySearch() {
      this.$axios({
        data: {
          query: `
          {
            citySearch(query:"${this.locationSearch}"){
              id
              name
              country
              county
              geolocation{
                lng
                lat
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          if (this.locationSearch != "") {
            this.locationSearchResults = result.data.data.citySearch;
          }
        })
        .catch(() => {});
    },
    debouncedCitySearch: debounce(
      function () {
        this.citySearch();
      },
      1000,
      { maxWait: 1200 }
    ),
    selectCity(location, eventIndex) {
      this.$store.dispatch("applyCityToEvent", {
        city: location,
        eventIndex: eventIndex,
      });
      this.locationSearch = "";
      this.locationSearchResults = [];

      this.removeError("event" + eventIndex);
    },
    toggleVisibleCourseSelectors(id) {
      if (this.visibleCourseSelectors.includes(id)) {
        this.visibleCourseSelectors = this.visibleCourseSelectors.filter(
          (selectors) => selectors != id
        );
      } else {
        this.visibleCourseSelectors.push(id);
      }
    },
    applyLayout(layout, eventIndex, roundIndex, poolIndex) {
      this.$store.dispatch("applyLayoutToPool", {
        layout: layout,
        eventIndex: eventIndex,
        roundIndex: roundIndex,
        poolIndex: poolIndex,
      });
      this.visibleCourseSelectors = [];

      this.removeError("event" + eventIndex);
    },
    adjustNumberOfPools() {
      let eventIndex = this.poolAdjustmentValues.eventIndex;
      let currentNumberOfPools = this.poolAdjustmentValues.pools;
      let newNumberOfPools = this.poolAdjustmentValues.newVal;
      if (currentNumberOfPools > newNumberOfPools) {
        let roundsToRemove = currentNumberOfPools - newNumberOfPools;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removePoolFromRounds", { eventIndex });
        }

        this.$refs.removepoolsmodal.close();
      } else if (newNumberOfPools > currentNumberOfPools) {
        let roundsToAdd = newNumberOfPools - currentNumberOfPools;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addPoolToRounds", { eventIndex });
        }
      }
    },
    adjustNumberOfRounds() {
      let eventIndex = this.roundAdjustmentValues.eventIndex;
      let currentNumberOfRounds = this.roundAdjustmentValues.rounds;
      let newNumberOfRounds = this.roundAdjustmentValues.newVal;

      if (currentNumberOfRounds > newNumberOfRounds) {
        let roundsToRemove = currentNumberOfRounds - newNumberOfRounds;

        for (let index = 0; index < roundsToRemove; index++) {
          this.$store.dispatch("removeRoundFromEvent", { eventIndex });
        }

        this.$refs.removeroundsmodal.close();
      } else if (newNumberOfRounds > currentNumberOfRounds) {
        let roundsToAdd = newNumberOfRounds - currentNumberOfRounds;

        for (let index = 0; index < roundsToAdd; index++) {
          this.$store.dispatch("addRoundToEvent", { eventIndex });
        }
      }
    },
    markErrors() {
      this.tour.events.forEach((event, eventIndex) => {
        let errorFound = false;
        if (event.name == null || event.name.length < 3) {
          this.erronousSections.push("event" + eventIndex);
          errorFound = true;
        }
        if (event.city == null) {
          this.erronousSections.push("event" + eventIndex);
          errorFound = true;
        }

        if (event.utility.checkInEnabled) {
          if (event.checkInStartsAt == null || event.checkInEndsAt == null) {
            this.erronousSections.push("event" + eventIndex);
            errorFound = true;
          }
        }

        event.rounds.forEach((round) => {
          round.pools.forEach((pool) => {
            if (pool.date == null || pool.layout == null) {
              this.erronousSections.push("event" + eventIndex);
              errorFound = true;
            }
          });
        });

        if (errorFound) {
          this.$store.dispatch("toggleEventSectionExpansion", {
            property: "expanded",
            value: true,
            eventIndex,
          });
        }
      });
    },
    validateAndProceed() {
      this.markErrors();

      if (this.erronousSections.length == 0) {
        this.saveTour();
      } else {
        this.$scrollTo("#" + this.erronousSections[0]);
      }
    },
    maxSpotsOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 999; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxSpots || (event.maxSpots == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    wildcardOptions(event) {
      let options = {
        colors: {
          primary: this.$store.state.colors.primary.twilight.hex,
          secondary: "#FFF",
        },
        values: [],
      };

      for (let index = 0; index <= 20; index++) {
        options.values.push({
          value: index,
          displayValue: index,
          default:
            index == event.maxWildcards ||
            (event.maxWildcards == null && index == 0)
              ? true
              : false,
          text: "",
        });
      }

      return options;
    },
    updateEventTimeField(date, eventIndex, property) {
      if (property == "registrationOpenAt" && date == null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: false,
        });
      }
      if (property == "registrationOpenAt" && date != null) {
        this.$store.dispatch("updateBoolOnEvent", {
          property: "autoOpenRegistration",
          eventIndex: eventIndex,
          value: true,
        });
      }
      this.$store.dispatch("updateEventTimeField", {
        eventIndex: eventIndex,
        property: property,
        date: moment(date).toISOString(),
      });
      this.removeError("event" + eventIndex);
    },
  },
  async beforeMount() {
    this.$store.dispatch("resetBuilderState");

    let globalData = await this.$axios({
      data: {
        query: `
          {
            pdgaEventTypes{
              name
              type
            }
            tourTypes{
              name
              type
            }
            divisionTypes{
              position
              name
              type
            }
            currencies{
              name
              code
              digits
              isEnabledForPayments
            }
          }
          `,
      },
    });

    this.$store.dispatch("updateGlobalData", globalData.data.data);
  },
  beforeCreate() {},
  destroyed() {
    this.$store.dispatch("resetBuilderState");
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
}
.add-event {
  cursor: pointer;
  margin: 16px 12px 0 12px;
  display: flex;
  height: 48px;
  padding: 0 20px;
  align-items: center;
  border-radius: 6px;
  background: white;
  box-shadow: 0 0 6px $fog;
  transition: all ease 0.3s;

  &:hover {
    box-shadow: 0 0 6px $blizzard;
  }

  p {
    margin: 0;
  }
  svg {
    margin-right: 10px;
  }
}

.event-control {
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  > div {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
    padding: 20px;
    border-top: 1px solid $fog;
  }

  .duplicate {
    @include Gilroy-Bold;
    display: flex;
    align-items: center;
    font-size: 14px;

    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .delete {
    color: $dusk;
    font-size: 14px;
    cursor: pointer;
    &.disabled {
      color: $sleet;
      cursor: default;
    }
  }
}
.general {
  background-color: white;
  padding: 15px;

  h4 {
    margin-top: 12px;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }
  .payment-text {
    margin-top: 10px;
  }

  h6 {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 1.8em;
  }

  textarea {
    resize: vertical;
    width: 100%;
    height: 250px;
    min-height: 250px;
    border: 1px solid $blizzard;
    transition: all ease 0.3s;
    border-radius: 6px;
    padding: 15px;
    margin-top: 10px;

    &::placeholder {
      color: $blizzard;
      @include Gilroy-Medium;
    }

    &:hover {
      border: 1px solid $midnight;
    }
  }
}
.copy-paste {
  background: white;
  > div {
    padding: 20px 20px 28px 20px;
    p {
      margin-bottom: 24px;
      svg {
        width: 18px;
        height: 18px;
        margin-bottom: 4px;
        margin-right: 8px;
      }
    }

    p,
    b {
      font-size: 14px;
    }
    b {
      @include Gilroy-Bold;
    }
    select,
    button {
      margin-top: 12px;
    }
    select {
      option {
        color: $midnight;
        @include Gilroy-Bold;
        &:disabled {
          color: $blizzard;
        }
      }

      &.disabled {
        color: $blizzard !important;
        @include Gilroy-Regular;
      }
    }
  }
}
.tournament-director {
  background-color: white;
  padding: 24px 15px 32px 15px;
  border-bottom: 1px solid $fog;

  h4 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .default-inputfield {
    margin-top: 24px;
  }
}
.details {
  span {
    width: 100%;
  }
  > div {
    border-top-left-radius: 6px;

    background: white;
    padding: 20px 20px 28px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    transition: all ease 0.3s;

    h5 {
      font-size: 16px;
      margin-bottom: 12px;

      span {
        color: $blizzard;
      }

      &.location {
        margin-top: 24px;
      }
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 24px;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
  a {
    font-size: 14px;
    color: $strom;
    margin-top: 8px;
  }

  .rounds,
  .pools-selector {
    width: 100%;

    h5 {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 24px;
      width: 100%;
    }
    p {
      font-size: 18px;
      width: 100%;
      text-align: left;
      margin-top: 24px;
      @include Gilroy-Bold;
      margin-bottom: 0;
    }
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blizzard;
      color: $blizzard;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 10px;
      @include Gilroy-Bold;
      transition: all ease 0.3s;

      &.active {
        border: 1px solid $twilight;
        background: $twilight;
        color: white;

        &:hover {
          color: white;
          cursor: default;
          border: 1px solid $twilight;
        }
      }

      &:hover {
        border: 1px solid $midnight;
        color: $midnight;
        cursor: pointer;
      }

      &.none {
        width: 64px;
      }
    }
  }

  .default-toggle {
    margin-top: 16px;
  }
  .feature-icon {
    height: 48px;
    width: auto;
  }
}
.registration-type {
  margin-top: 24px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  background: white;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
  }
  .default-radio-buttons {
    margin-top: 16px;
  }
}

.settings-header,
.contact-details-header {
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  background-color: $fog;
  margin-top: 1px;
  h4 {
    font-size: 18px;
    margin: 0;
    span {
      @include Gilroy-Regular;
    }
  }
}

.misc-settings {
  padding: 20px 20px 28px 20px;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
  }

  .features {
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-bottom: 1px solid $fog;
    padding-bottom: 22px;
    margin-bottom: 24px;

    .verifier,
    .signature {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 50%;
      h5 {
        margin-top: 18px;
        margin-bottom: 18px;
        font-size: 16px;
        text-align: center;
      }

      .feature-icon {
        height: 48px;
      }
    }
  }
}
.wildcards,
.max-players {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .selector-container {
    margin-top: 12px;
  }
}
.wildcards,
.group-size {
  margin-top: 24px;
  padding-top: 22px;
  border-top: 1px solid $fog;
}
.group-size {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  p {
    font-size: 18px;
    width: 100%;
    text-align: left;
    margin-top: 24px;
    @include Gilroy-Bold;
    margin-bottom: 0;
  }

  .item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blizzard;
    color: $blizzard;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 12px;
    @include Gilroy-Bold;
    transition: all ease 0.3s;
    font-size: 16px;

    &.active {
      border: 1px solid $twilight;
      background: $twilight;
      color: white;

      &:hover {
        color: white;
        cursor: default;
        border: 1px solid $twilight;
      }
    }

    &:hover {
      border: 1px solid $midnight;
      color: $midnight;
      cursor: pointer;
    }

    &.none {
      width: 64px;
    }
  }
}
.registration-open {
  display: flex;
  flex-direction: column;
  background: white;
  border-top: 1px solid $fog;
  margin-top: 24px;
  padding: 20px 0px 0px 0px;

  h5 {
    font-size: 16px;
    margin: 0;
  }

  .datepicker {
    margin-top: 12px;
  }

  .auto-open {
    margin-top: 24px;

    .header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        font-size: 14px;
        margin: 0;
      }

      h5 {
        font-size: 16px;
        @include Gilroy-Bold;
        margin: 0;
      }
    }
    p {
      margin-top: 14px;
      font-size: 14px;
    }
  }
}

.waitinglist-type {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  margin-top: 16px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  border-radius: 6px;
  width: 100%;

  .content {
    margin-top: 12px;
  }
}
.check-in {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  margin-top: 16px;
  padding: 20px 0px 28px 0px;
  border-top: 1px solid $fog;
  border-radius: 6px;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-direction: row;
  }
  > div {
    width: 100%;
    margin-top: 0px;
  }

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }

  h6 {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h5 {
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
  }
  .closes {
    margin-top: 8px;
  }
}
.pdga-tier {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid $fog;
  width: 100%;
  .radios {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .default-radio-buttons {
      width: 50%;
      margin-top: 16px;

      &:nth-child(even) {
        padding-left: 30px;
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-direction: row;
  }

  h5 {
    margin-bottom: 0;
  }
}
.nav-buttons {
  margin: 22px 12px 0 12px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}

.wrapper {
  box-shadow: 0 0 0 1px $sleet;
  border-radius: 6px;
  transition: all ease 0.3s;

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }

  .event-header {
    background: $fog;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    padding: 0 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    h4 {
      font-size: 18px;
      @include Gilroy-Bold;
      margin: 0;
    }
  }
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 100%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
}
.set-location {
  border: 1px solid $blizzard;
  border-radius: 6px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &:hover {
    border: 1px solid $midnight;
  }

  .remove-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    .a {
      fill: $dusk;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
}
.find-location {
  width: 100%;
  border: 1px solid $blizzard;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &.expanded {
    padding-bottom: 20px;
    transition: all ease 0.3s;
  }

  &:hover {
    border: 1px solid $midnight;
  }

  input {
    height: 46px;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    transition: all ease 0.3s;

    &:focus {
      border: 0px solid transparent;
      transition: all ease 0.3s;
    }

    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transition: all 0.3s ease;
    max-height: 0;
    padding: 0px 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    width: 100%;
    li {
      cursor: pointer;
      transition: all ease 0.3s;
      max-height: 50px;
      overflow: hidden;
      padding: 5px 15px;
      transition: all ease 0.3s;
      width: 100%;
      display: flex;

      &:hover {
        background-color: $mist;
        transition: all ease 0.3s;
      }
    }
  }
}
.find-location {
  width: 100%;
  border: 1px solid $blizzard;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
  @include Gilroy-Bold;
  transition: all ease 0.3s;

  &.expanded {
    padding-bottom: 20px;
    transition: all ease 0.3s;
  }

  &:hover {
    border: 1px solid $midnight;
  }

  input {
    height: 46px;
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
    transition: all ease 0.3s;

    &:focus {
      border: 0px solid transparent;
      transition: all ease 0.3s;
    }

    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transition: all 0.3s ease;
    max-height: 0;
    padding: 0px 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    width: 100%;
    li {
      cursor: pointer;
      transition: all ease 0.3s;
      max-height: 50px;
      overflow: hidden;
      padding: 5px 15px;
      transition: all ease 0.3s;
      width: 100%;
      display: flex;

      &:hover {
        background-color: $mist;
        transition: all ease 0.3s;
      }
    }
  }
}

.pools {
  .wrapper {
    margin: 0;
    box-shadow: none;
  }

  > div {
    background: white;
    border-radius: 0;

    &.error {
      box-shadow: 0 0 0 2px $dusk;
    }

    header {
      @include Gilroy-Bold;
      padding: 9px 12px;
      background: $mist;

      h4 {
        font-size: 18px;
        margin: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin-bottom: 0;

      span {
        @include Gilroy-Regular;
        color: $strom;
        font-size: 12px;
      }
    }

    .when {
      border-bottom: 1px solid $fog;
      margin-left: 20px;
      margin-right: 20px;
      padding-bottom: 24px;
      padding-top: 20px;

      h5 {
        margin-bottom: 12px;
      }
    }
    .starting-format {
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid $fog;
      padding-bottom: 24px;

      display: flex;
      flex-wrap: wrap;
      h5 {
        width: 100%;
        margin-bottom: 14px;
        margin-top: 24px;
      }
      .default-radio-buttons {
        width: 50%;
        display: inline;
      }
    }
    .where {
      position: relative;
      margin: 24px 20px 0px 20px;
      padding-bottom: 28px;

      h5 {
        margin-bottom: 0px;
      }
      .small-button {
        position: absolute;
        right: 15px;
        top: -2px;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .copy-paste {
    > div {
      padding: 40px 48px;
      display: flex;
      flex-wrap: wrap;
      select {
        margin: 0;
        margin-top: 16px;
      }
      p {
        width: 100%;
        font-size: 16px;
        margin-bottom: 32px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
      span {
        margin-right: 72px;
        b {
          font-size: 16px;
        }
        button {
          margin-top: 16px;
        }
      }
    }
  }
  .event-control {
    > div {
      height: 96px;

      padding: 0 48px;
    }
    .duplicate {
      padding: 12px 20px;
      border-radius: 10px;
      font-size: 16px;
      &:hover {
        background: $mist;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .delete {
      font-size: 16px;
    }
  }
  .settings-header,
  .contact-details-header {
    height: 48px;
    padding: 0 24px;
    background-color: $fog;
    margin-top: 2px;
    h4 {
      font-size: 20px;
      margin: 0;
    }
    svg {
      cursor: pointer;
      width: 21px;
      height: 12px;
    }
  }
  .pools {
    > div {
      header {
        height: 48px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        width: 100%;

        h4 {
          font-size: 20px;
          width: 100%;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      border-radius: 0px;
      margin: 0;

      .startwhenwrapper {
        width: 40%;

        .starting-format {
          padding-right: 48px;
          padding-left: 48px;
          margin: 0;
          padding-bottom: 42px;
          h5 {
            font-size: 20px;
            padding-top: 42px;
            margin-top: 0;
            margin-bottom: 24px;
          }
        }

        .when {
          padding-top: 42px;
          padding-right: 48px;
          padding-left: 48px;
          border-bottom: none;
          margin: 0 0 32px 0;

          h5 {
            font-size: 20px;
            margin-bottom: 24px;
          }
        }
      }

      .where {
        width: 60%;
        margin: 0;
        padding-left: 48px;
        border-left: 1px solid $fog;
        padding-right: 48px;

        .small-button {
          top: 38px;
          right: 48px;
        }

        h5 {
          font-size: 20px;
          padding-top: 42px;
        }
      }
    }
  }
  .wrapper {
    .event-header {
      cursor: pointer;
      height: 48px;
      padding: 0 24px;
      h4 {
        font-size: 24px;
      }

      svg {
        width: 21px;
        height: 12px;
      }
    }
  }

  .tournament-director,
  .general {
    padding: 40px 48px 52px 48px;
    border-radius: 0px;

    h4 {
      font-size: 20px;
    }
    h6 {
      font-size: 16px;
      margin-top: 32px;
      margin-bottom: 0;
    }

    .default-inputfield {
      margin-top: 32px;
      width: 100%;
    }
  }
  .general {
    padding-top: 40px;
    border-radius: 0 0 6px 6px;

    h4 {
      margin: 0;
    }
  }
  .details {
    > div {
      display: flex;
      flex-direction: row;
      padding: 40px 48px 52px 48px;
      border-radius: 0px;
    }

    span {
      h5 {
        font-size: 20px;
        margin-bottom: 20px;

        &.location {
          margin-top: 36px;
        }
      }
      .default-inputfield {
        width: 80%;
      }
      .set-location {
        width: 80%;
      }
      .find-location {
        width: 80%;
      }
    }
    span:first-of-type {
      width: 55%;
    }
    span:last-of-type {
      width: 45%;
    }

    .rounds {
      h5 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
        margin-right: 11px;
      }
    }
    .pools-selector {
      h5 {
        margin-top: 36px;
        font-size: 20px;
        margin-bottom: 20px;
      }

      .item {
        width: 48px;
        height: 48px;
        margin-top: 0px;
      }
    }
  }
  main {
    padding: 0;
    > .wrapper {
      margin-top: 0px;
    }
  }

  .registration-type {
    padding: 40px 48px 52px 40px;
    border-radius: 0;
    margin-top: 0px;
    h5 {
      font-size: 20px;
      margin: 0;
      text-align: left;
    }
    .default-radio-buttons {
      margin-top: 32px;
    }
  }
  .pdga-tier {
    padding: 40px 48px 40px 40px;
    border-radius: 0px;
    transition: all ease 0.3s;

    &.expanded {
      padding-bottom: 52px;
      transition: all ease 0.3s;
    }

    h5 {
      font-size: 20px;
    }
    .radios {
      display: flex;
      width: 100%;
      .default-radio-buttons {
        width: 20%;
        margin-top: 32px;
        &:nth-child(even) {
          padding: 0;
        }
      }
    }
  }

  .waitinglist-type {
    margin-top: 0px;
    border-radius: 10px;
    padding: 40px 48px 40px 40px;
    h5 {
      font-size: 20px;
    }

    .content {
      margin-top: 24px;
    }
  }
  .check-in {
    margin-top: 0px;
    border-radius: 10px;
    padding: 40px 48px 40px 40px;
    transition: all ease 0.3s;

    .header {
      svg {
        cursor: pointer;
        width: 21px;
        height: 12px;
      }
    }

    &.expanded {
      padding-bottom: 52px;
      transition: all ease 0.3s;
    }
    h5 {
      font-size: 20px;
    }
    > div {
      display: flex;
    }

    .open {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      border-right: 1px solid $fog;

      h6 {
        margin: 0;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .datepicker {
        width: 60%;
      }
    }
    .closes {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .datepicker {
        width: 60%;
      }
      h6 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
  .registration-open {
    border-radius: 0;
    padding: 40px 48px 52px 40px;
    flex-direction: row;
    .left {
      width: 40%;
      border-right: 1px solid $fog;
      padding-right: 48px;
      h5 {
        margin: 0;
        text-align: left;
      }
    }
    .datepicker {
      margin-top: 24px;
    }

    .auto-open {
      width: 60%;
      padding-left: 48px;
      margin-top: 0;
      .header {
        h6 {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
      }
    }

    h5 {
      font-size: 20px;
    }
  }
  .misc-settings {
    padding: 0;
    display: flex;

    .features {
      display: flex;
      width: 100%;

      .verifier,
      .signature {
        padding-top: 32px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;

        .feature-icon {
          height: 52px;
        }
        h5 {
          font-size: 20px;
          text-align: center;
          margin-bottom: 32px;
        }
      }
    }

    .max-players {
      width: 30%;
      padding-top: 42px;
      justify-content: center;
      .selector-container {
        margin: 0 0 52px 0;
      }

      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }
    }
    .group-size {
      width: 40%;
      border-right: 1px solid $fog;
      border-left: 1px solid $fog;
      border-top: none;
      padding: 0;
      margin: 0;
      justify-content: center;
      padding-top: 42px;
      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }

      .item {
        margin: 0 6px 52px 6px;
        height: 48px;
        width: 48px;
        font-size: 16px;
      }
    }
    .wildcards {
      width: 30%;
      border-top: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      padding-top: 42px;
      h5 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 32px;
      }
      .selector-container {
        margin: 0 0 52px 0;
      }
    }
    .registration-open {
      width: 100%;
    }
  }

  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
  .nav-buttons {
    width: 100%;
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 2px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
}
</style>