<template>
  <button
    @click="$emit('click')"
    class="StandardSmallBorderedButton"
    :class="{ disabled: disabled, fluid: fluid }"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "StandardSmallSolidButton",
  props: ["title", "disabled", "fluid"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.StandardSmallBorderedButton {
  background-color: $twilight;
  border: 1px solid $twilight;
  color: white;
  height: 24px;
  font-size: 12px;
  @include Gilroy-Bold;
  transition: 0.3s;
  letter-spacing: 0.16px;
  cursor: pointer;
  width: 100%;

  &.fluid {
    width: auto;
    padding: 0 8px;
  }

  &.disabled {
    border-color: $sleet;
    color: $sleet;
    cursor: default;
  }

  &:hover {
    border-color: $twilightshade;
    background-color: $twilightshade;
  }
}

@media (min-width: 992px) {
  button {
    &.StandardSmallBorderedButton {
      @include Gilroy-Bold;
    }
  }
}
</style>
