<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    :class="[color, direction]"
  >
    <path
      class="a"
      d="M12.6,0,7,5.333,1.4,0,0,1.333,7,8l7-6.667Z"
      transform="translate(0 0)"
    />
  </svg>
</template>

<script>
export default {
  props: ["color", "direction"],
  name: "Chevron2",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #2d323c;
  transition: all ease 0.3s;
}
svg {
  transition: all ease 0.3s;
}
.left {
  transform: rotate(90deg);
}
.right {
  transform: rotate(-90deg);
}
.up {
  transform: rotate(-180deg);
}
.down {
  transform: rotate(0);
}

.ocean {
  .a {
    fill: #05b5bc;
  }
}
.sleet {
  &:hover {
    .a {
      fill: $strom;
    }
  }
  .a {
    fill: #d2d7dc;
  }
}
</style>