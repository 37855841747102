<template>
  <main v-if="loaded">
    <slide-up-down
      class="layout-picker-slide"
      :active="edit == true"
      :duration="500"
    >
      <header>
        Add a course layout by searching for official layouts or creating a
        custom one.
        <template v-if="usersPosition != null">
          Nearby courses are displayed below.
        </template>
      </header>
      <section class="tabs">
        <div @click="view = 'PUBLIC'" :class="{ active: view == 'PUBLIC' }">
          Official
        </div>
        <div @click="view = 'CUSTOM'" :class="{ active: view == 'CUSTOM' }">
          Custom
        </div>
      </section>
      <section class="layout-picker">
        <section class="search" v-if="view == 'PUBLIC'">
          <input
            type="text"
            class="search-field"
            v-model="searchString"
            placeholder="Search for courses"
            @keydown="debouncedCourseSearch()"
          />
          <Search color="midnight" />
        </section>
        <section class="courses" v-if="view == 'CUSTOM'">
          <TheCourseCard
            :course="course"
            v-for="course in myCourses"
            :key="course.id"
            :applyLayoutEnabled="true"
            @apply="$emit('apply-layout', $event)"
          />
        </section>
        <section class="courses" v-if="view == 'PUBLIC'">
          <TheCourseCard
            :course="course"
            v-for="course in shownPublicCourses"
            :key="course.id"
            :applyLayoutEnabled="true"
            :usersPosition="usersPosition"
            @apply="$emit('apply-layout', $event)"
          />
        </section>

        <p class="foot" v-if="view == 'PUBLIC' && searchString.length > 0">
          If you think a course is incorrect or missing then please send us an
          <a href="mailto:hello@tjing.se">email</a>.
        </p>
        <p class="foot" v-if="view == 'CUSTOM'">
          To create a new custom layout or course use the
          <router-link :to="{ name: 'coursemanagerbeta' }">
            course manager</router-link
          >.
        </p>
      </section>
    </slide-up-down>
    <section class="current-layout" v-if="pool.layout != null">
      <p class="small" v-if="edit"><b>Current layout applied:</b></p>
      <p>
        Course: <span>{{ pool.layout.course.name }}</span>
      </p>
      <p>
        Layout:
        <span
          >{{ pool.layout.name }}
          <Verified v-if="pool.layout.type == 'PUBLIC'" />
          <span class="custom-tag" v-if="pool.layout.type == 'CUSTOM'"
            >custom</span
          >
        </span>
      </p>
      <ul class="tags">
        <li class="holes">
          {{ pool.layout.latestVersion.holes.length }} Holes
        </li>
        <li class="par">Par {{ parCalc }}</li>
      </ul>
      <div class="layout">
        <ul
          class="holes"
          :class="{ wrap: wrapHoles(pool.layout.latestVersion.holes) }"
        >
          <li
            class="hole"
            v-for="hole in pool.layout.latestVersion.holes"
            :key="hole.id"
            :class="{}"
          >
            <div class="hole-name">{{ displayHole(hole) }}</div>
            <div class="hole-par" :class="parEval(hole)">{{ hole.par }}</div>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
import { debounce, filter } from "lodash";
import locationMixin from "@/mixins/locationMixin.js";
import Search from "@/assets/icons/Search";
import Verified from "@/assets/icons/Verified";
import TheCourseCard from "@/components/TheCourseCard";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "TheBuilderLayoutPicker",
  props: ["poollength", "pool", "edit"],
  components: { Search, TheCourseCard, SlideUpDown, Verified },
  mixins: [locationMixin],
  data() {
    return {
      searchString: "",
      view: "PUBLIC",
      publicCourses: [],
      usersPosition: null,
      myCourses: [],
      loaded: false,
    };
  },
  computed: {
    parCalc() {
      let par = 0;

      this.pool.layout.latestVersion.holes.forEach((hole) => {
        par += hole.par;
      });

      return par;
    },
    shownPublicCourses() {
      return this.filteredPublicCourses.slice(0, 8);
    },
    filteredPublicCourses() {
      let temp = filter(
        this.publicCourses,
        (o) =>
          o.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          o.address.toLowerCase().includes(this.searchString.toLowerCase())
      );

      if (this.usersPosition == null) {
        return temp;
      }

      return temp.sort((a, b) => {
        let AeventDistanceFromUser = this.getDistanceInKM(
          a.geolocation.lat,
          a.geolocation.lng,
          this.usersPosition.lat,
          this.usersPosition.lng
        );
        let BeventDistanceFromUser = this.getDistanceInKM(
          b.geolocation.lat,
          b.geolocation.lng,
          this.usersPosition.lat,
          this.usersPosition.lng
        );

        if (AeventDistanceFromUser > BeventDistanceFromUser) return 1;
        if (AeventDistanceFromUser < BeventDistanceFromUser) return -1;
        else return 0;
      });
    },
  },
  methods: {
    debouncedCourseSearch: debounce(
      function () {
        this.courseSearch();
      },
      500,
      { maxWait: 800 }
    ),
    async closestCoursesToUser() {
      let courses = await this.$axios({
        data: {
          query: `
          {
            courses(first: 6, filter:{ geolocation: {lng: ${this.usersPosition.lng},lat:${this.usersPosition.lat}} })
            {
              id
              name
              address
              type
              geolocation{
                lng
                lat
              }
              layouts{
                type
                id
                name
                course{
                  name
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.publicCourses = courses.data.data.courses;
    },
    async courseSearch() {
      let courses = await this.$axios({
        data: {
          query: `
          {
            courses(first: 5, filter:{ search:"${this.searchString}" })
            {
              id
              name
              address
              type
              geolocation{
                lng
                lat
              }
              layouts{
                type
                id
                name
                course{
                  name
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.publicCourses = courses.data.data.courses;
    },
    getDistanceInKM(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    async loadAllCourses() {
      let allCourses = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
            {
              me{
                courses{
                  id
                  name
                  layouts{
                    id
                    name
                    latestVersion{
                      holes{
                        id
                        number
                        par
                      }
                    }
                  }
                }
              }
            }
          `,
        },
      });

      this.myCourses = allCourses.data.data.me.courses;
      this.loaded = true;
    },
    wrapHoles(holes) {
      let wrap = false;

      if (holes.length > 27) {
        wrap = true;
      } else if (holes.length > 18) {
        holes.forEach((hole) => {
          if (hole.name != null) {
            wrap = true;
          }
        });
      }

      return wrap;
    },
    displayHole(hole) {
      return hole.name == null ? hole.number : hole.name;
    },
    parEval(hole) {
      if (hole.par <= 3) {
        return "low";
      } else if (hole.par == 4) {
        return "mid";
      } else return "high";
    },
  },
  async mounted() {
    try {
      this.usersPosition = await this.getLocation();
      await this.closestCoursesToUser();
    } catch (err) {
      if (err.code == 1) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services disabled",
          type: "error",
        });
      }
    }

    await this.loadAllCourses();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.layout-picker-slide {
  header {
    font-size: 14px;
  }
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 16px 0 18px 0;

  div {
    font-size: 16px;
    height: 32px;
    width: 92px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    transition: all ease 0.3s;
    background: $mist;
    cursor: pointer;
    font-size: 14px;

    &.active {
      background: $ocean;
      color: white;
      @include Gilroy-Bold;
      &:hover {
        background: $ocean;
        color: white;
        @include Gilroy-Bold;
      }
    }
    &:hover {
      background: $fog;
    }
  }
}
.courses {
  > main {
    margin: 0px 0px 24px 0px;

    &:last-of-type {
      margin: 0;
    }
  }
}

.current-layout {
  p {
    font-size: 14px;

    span {
      @include Gilroy-Bold;
      display: inline-flex;
      align-items: center;
      svg {
        margin-left: 3px;
        height: 14px;
        margin-bottom: 2px;
      }
    }
  }
  .custom-tag {
    margin-left: 8px;
    font-size: 6px;
    text-transform: uppercase;
    background-color: $sunset;
    color: white;
    letter-spacing: 0.8px;
    padding: 3px 4px 2px 4px;
    border-radius: 10px;
    height: 12px;
    display: inline-flex;
    margin-bottom: 2px;
  }
}
.search {
  position: relative;
  margin-bottom: 24px;
  svg {
    position: absolute;
    left: 24px;
    top: 16px;
  }
  .search-field {
    width: 100%;
    height: 48px;
    border-radius: 50px;
    border: 1px solid $blizzard;
    background-color: transparent;
    padding-left: 50px;
    padding-right: 50px;
    outline: none;
    transition: all ease 0.3s;
    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
    &:hover,
    &:focus {
      border: 1px solid $strom;
    }
  }
}

.layout-picker {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h6 {
    text-align: center;
    margin: 0px 0 24px 0;
    font-size: 14px;
  }

  .foot {
    font-size: 16px;
    text-align: center;
    margin: 24px 0 0px 0;
    a {
      color: $ocean;
    }
    span {
      color: $ocean;
      cursor: pointer;
    }
  }
}
.tags {
  list-style: none;
  margin: 14px 0 0 0;
  padding: 0;
  display: flex;

  li {
    background: $mist;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid $sleet;
    padding: 3px 5px;
    color: $strom;
    font-size: 12px;
    @include Gilroy-Bold;
    width: auto;

    &.holes {
      color: $ocean;
      border: 1px solid #9be1e4;
      background: #e5f8f8;
    }
  }
}
p {
  margin-bottom: 0px;
  &.small {
    margin-top: 24px;
    font-size: 12px;
  }
}
.layout {
  .holes {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    &.wrap {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 24px;
      margin: 0;

      li {
        width: calc(5.5555555556%);
        flex: unset;
        box-sizing: border-box;
      }
    }

    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid white;
      margin-top: 15px;
      max-width: 40px;

      &:last-child {
        border-right: 0px solid white;
      }
      .hole-name {
        font-size: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
      }
      .hole-par {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 10px;
        @include Gilroy-Bold;

        padding: 5px 0;

        &.low {
          background-color: $mist;
        }
        &.mid {
          background-color: $sleet;
        }
        &.high {
          background-color: $blizzard;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    > main {
      width: calc(50% - 15px);

      &:last-of-type {
        margin-bottom: 24px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .layout-picker-slide {
    header {
      font-size: 16px;
      margin-top: 12px;
      line-height: 1.4;
    }
  }
  .tabs {
    justify-content: flex-start;
    padding: 0;
    > div {
      margin-right: 32px;
      font-size: 16px;
    }
  }
  .layout-picker {
    h6 {
      text-align: left;
      font-size: 14px;
    }
    header {
      font-size: 16px;
    }
  }
  .current-layout {
    p {
      font-size: 16px;
      &.small {
        font-size: 14px;
      }
    }
  }
  .layout {
    .holes {
      li {
        border-right: 4px solid white;
        margin-top: 25px;
        .hole-name,
        .hole-par {
          font-size: 14px;
        }
      }
    }
  }

  .courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > main {
      cursor: pointer;
      margin: 0px 24px 24px 0px;
      width: calc(50% - 12px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
