<template>
  <div
    class="default-radio-buttons"
    :class="{
      extramargin: options.description && options.description != '',
      disabled: options.disabled,
    }"
  >
    <label class="radio-container">
      <input
        type="radio"
        v-model="radioButtonValue"
        :value="options.value"
        :name="groupname"
        :disabled="options.disabled"
      />
      <span class="radiomark"></span>
      <div class="text" v-if="options.description">
        <h6 class="name">{{ options.title }}</h6>
        <p v-if="options.description != ''">
          {{ options.description }}
        </p>
      </div>
      <h6 v-else class="name">{{ options.title }}</h6>
    </label>
  </div>
</template>

<script>
export default {
  name: "DefaultRadioButton",
  props: ["value", "disabled", "groupname", "options"],
  data() {
    return {};
  },
  computed: {
    radioButtonValue: {
      get: function () {
        return this.value;
      },
      set: function () {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit("change", this.options.value);
      },
    },
  },
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.default-radio-buttons {
  h6 {
    margin-bottom: 0px;
    font-size: 14px;
  }

  p {
    margin: 5px 0 0 0;

    font-size: 14px;
  }

  &.extramargin {
    margin-bottom: 18px;
  }

  &.disabled {
    h6,
    p {
      color: $blizzard;
    }
  }

  .text {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .radio-container {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: auto;
    min-height: 28px;
    display: flex;
    align-items: center;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .radiomark {
    position: absolute;
    /*top: 50%;
    transform: translateY(-50%);*/

    top: 0;
    left: 0;
    height: 27px;
    width: 27px;
    border: 2px solid $blizzard;
    border-radius: 50%;
    transition: all ease-in-out 0.3s;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .radiomark {
    border: 2px solid $midnight;
  }

  .radio-container input:disabled ~ .radiomark {
    border: 2px solid $fog;
  }
  .radio-container:hover input:disabled ~ .radiomark {
    border: 2px solid $fog;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .radiomark {
    background-color: $twilight;
    border: 2px solid $twilight;

    &:hover {
      border: 2px solid $twilightshade;
    }
  }
  .radio-container input:checked:disabled ~ .radiomark {
    background-color: $fog;
    border: 2px solid $fog;
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radiomark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .radiomark:after {
    display: block;
  }

  .radio-container input:disabled ~ .radiomark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .radio-container .radiomark:after {
    top: 6px;
    left: 6px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .default-radio-buttons {
    &.extramargin {
      margin-bottom: 0px;
    }
    h6 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>
