<template>
  <main @click="expandCard()" :class="{ expanded }">
    <section class="header">
      <p>{{ courseInfo.name }}</p>
      <span v-if="course.type == 'PUBLIC' && usersPosition" class="distance"
        >{{ distanceInKm }} km</span
      >

      <StandardSmallSolidButton
        v-if="course.type == 'CUSTOM' && applyLayoutEnabled == false"
        title="New layout"
        :fluid="true"
        @click="$emit('create-layout', course)"
      />
    </section>
    <section class="initial-info" :class="{ collapsed: expanded }">
      <div class="layouts">{{ layoutCounter(courseInfo) }}</div>
      <div class="address">{{ courseInfo.address }}</div>
    </section>

    <template v-if="additionalCourseInfo != null">
      <section
        class="map"
        v-if="course.type == 'PUBLIC' && expanded"
        @click.stop
      >
        <div ref="map"></div>
      </section>
      <section
        class="detailed-info"
        v-for="layout in courseInfo.layouts"
        :key="layout.id"
        :class="{ collapsed: !expanded }"
        @click.stop="expanded = false"
      >
        <div class="name">
          <h6>{{ layout.name }} <Verified v-if="layout.type == 'PUBLIC'" /></h6>

          <span class="custom-tag" v-if="course.type == 'CUSTOM'">custom</span>
          <StandardSmallSolidButton
            v-if="applyLayoutEnabled"
            title="Apply"
            :fluid="true"
            @click="$emit('apply', layout)"
          />
        </div>
        <ul class="tags">
          <li class="holes">{{ layout.latestVersion.holes.length }} Holes</li>
          <li class="par">Par {{ parCalc(layout.latestVersion) }}</li>
        </ul>
        <ul
          class="holes"
          :class="{ wrap: wrapHoles(layout.latestVersion.holes) }"
        >
          <li
            class="hole"
            v-for="hole in layout.latestVersion.holes"
            :key="hole.id"
            :class="{}"
          >
            <div class="hole-name">{{ displayHole(hole) }}</div>
            <div class="hole-par" :class="parEval(hole)">{{ hole.par }}</div>
          </li>
        </ul>
      </section>
    </template>
  </main>
</template>

<script>
import locationMixin from "@/mixins/locationMixin.js";
import StandardSmallSolidButton from "@/components/UIElements/StandardSmallSolidButton";
import Verified from "@/assets/icons/Verified";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "TheCourseCard",
  props: ["course", "applyLayoutEnabled", "usersPosition"],
  components: {
    StandardSmallSolidButton,
    Verified,
  },
  mixins: [locationMixin],
  watch: {
    expanded: function (newVal, oldVal) {
      if (
        newVal == true &&
        this.mapLoaded == false &&
        this.additionalCourseInfo.geolocation
      ) {
        this.loader
          .load()
          .then(() => {
            const myLatLng = { lat: -25.363, lng: 131.044 };
            const map = new google.maps.Map(this.$refs.map, this.mapOptions);
            let marker = new google.maps.Marker({
              position: this.course.geolocation,
              map,
              title: this.course.name,
              icon: process.env.BASE_URL + "event-location-pin.png",
              url: `https://maps.google.com/?q=${this.course.geolocation.lat},${this.course.geolocation.lng}`,
            });
            google.maps.event.addListener(marker, "click", function () {
              window.open(this.url, "_blank");
            });
          })
          .catch((e) => {
            // do something
          });
      }
    },
  },
  data() {
    return {
      expanded: false,
      additionalCourseInfo: null,
      loader: null,
      mapOptions: null,
      mapLoaded: false,
      gmap: null,
    };
  },
  computed: {
    distanceInKm() {
      let distance = 0;
      if (this.usersPosition != null && this.course.type == "PUBLIC") {
        distance = this.getDistanceInKM(
          this.course.geolocation.lat,
          this.course.geolocation.lng,
          this.usersPosition.lat,
          this.usersPosition.lng
        );
      } else {
        distance = null;
      }
      return distance == null ? null : Math.round(distance * 10) / 10;
    },
    courseInfo() {
      if (this.additionalCourseInfo == null) {
        return this.course;
      } else {
        return this.additionalCourseInfo;
      }
    },
  },
  methods: {
    wrapHoles(holes) {
      let wrap = false;

      if (holes.length > 27) {
        wrap = true;
      } else if (holes.length > 18) {
        holes.forEach((hole) => {
          if (hole.name != null) {
            wrap = true;
          }
        });
      }

      return wrap;
    },
    displayHole(hole) {
      return hole.name == null ? hole.number : hole.name;
    },
    parCalc(layout) {
      let par = 0;

      layout.holes.forEach((hole) => {
        par += hole.par;
      });

      return par;
    },
    parEval(hole) {
      if (hole.par <= 3) {
        return "low";
      } else if (hole.par == 4) {
        return "mid";
      } else return "high";
    },
    layoutCounter(course) {
      let length = course.layouts.length;
      return course.layouts.length == 1
        ? `${length} Layout`
        : `${length} Layouts`;
    },
    async expandCard() {
      if (this.additionalCourseInfo == null) {
        await this.loadCourseInfo();
      }
      this.expanded = !this.expanded;
    },
    async loadCourseInfo() {
      let courseInfo = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
          {
            course(courseId: "${this.course.id}") {
              id
              name
              address
              geolocation{
                lng
                lat
              }
              layouts {
                name
                type
                course{
                  name
                  id
                }
                latestVersion {
                  id
                  holes {
                    id
                    number
                    par
                    name
                  }
                }
              }
            }
          }

          `,
        },
      });

      this.additionalCourseInfo = courseInfo.data.data.course;
      if (courseInfo.data.data.course.geolocation) {
        this.mapOptions = {
          disableDefaultUI: true,
          center: {
            lat: courseInfo.data.data.course.geolocation.lat,
            lng: courseInfo.data.data.course.geolocation.lng,
          },
          zoom: 10,
          styles: [
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [{ color: "#81DADD" }],
            },
            {
              featureType: "landscape",
              elementType: "geometry.fill",
              stylers: [{ color: "#FAFAFA" }],
            },

            {
              featureType: "poi",
              elementType: "labels.icon",
              stylers: [{ color: "#B40068" }],
            },
            {
              elementType: "labels.text.fill",
              stylers: [{ color: "#2D323C" }],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [{ color: "#FAFAFA" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [{ color: "#7D8296" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#7D8296" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#2D323C" }],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry.fill",
              stylers: [{ color: "#B4B6C2" }],
            },
            {
              featureType: "road.local",
              elementType: "geometry.stroke",
              stylers: [{ color: "#B4B6C2" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ lightness: -10 }, { saturation: -83 }],
            },
          ],
        };
      }
    },
  },
  mounted() {
    this.loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_JS_KEY,
      version: "weekly",
      libraries: ["places"],
    });
  },
  beforeMount() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
main {
  box-shadow: 0 0 0 1px $blizzard;
  border-radius: 6px;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: all ease 0.3s;
  box-sizing: content-box;
  overflow: hidden;
  background-color: white;

  &.expanded {
    box-shadow: 0 0 0 2px $ocean;
    padding-bottom: 24px;
  }

  section {
    .header {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        max-width: 80%;
        font-size: 18px;
        @include Gilroy-Bold;
        margin: 0;
        line-height: 18px;
      }

      .distance {
        background: #fff7e5;
        border: 1px solid #ffe199;
        color: $sunrise;
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 4px;
        @include Gilroy-Bold;
        white-space: nowrap;
      }

      .link {
        color: $twilight;
        font-size: 14px;
      }
    }
    .initial-info {
      padding: 10px 20px 0 20px;
      transition: all ease 0.3s;
      overflow: hidden;
      max-height: 300px;
      opacity: 1;
      &.collapsed {
        transition: all 0, 3s;
        max-height: 0;
        padding: 0 20px;
        opacity: 0;
      }

      .layouts {
        font-size: 14px;
        @include Gilroy-Bold;
        margin-bottom: 8px;
      }
      .address {
        font-size: 12px;
      }
    }
    .map {
      padding: 15px;
      height: 250px;

      div {
        width: 100%;
        height: 250px;
        border-radius: 10px;
        border: 1px solid $blizzard;
      }
    }
    .detailed-info {
      transition: all ease 0.3s;
      overflow: hidden;
      max-height: 300px;
      padding: 15px 20px 0px 20px;
      opacity: 1;
      border-top: 1px solid $blizzard;
      margin-top: 15px;

      &.collapsed {
        transition: all 0.1s;
        transform: translateY(100px);
        transform-origin: bottom;
        transition: all ease 0.3s;
        max-height: 0;
        padding: 0 20px;
        opacity: 0;

        border-top: 0px solid $fog;
        margin-top: 0px;
      }

      .name {
        h6 {
          @include Gilroy-Bold;
          font-size: 18px;
          margin: 0;
          padding-right: 15px;

          svg {
            margin-bottom: 3px;
          }
        }

        @include Gilroy-Bold;
        font-size: 18px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .custom-tag {
          margin-left: 8px;
          font-size: 6px;
          text-transform: uppercase;
          background-color: $sunset;
          color: white;
          letter-spacing: 0.8px;
          padding: 3px 4px 2px 4px;
          border-radius: 10px;
        }

        button {
          margin-left: auto;
        }
      }
      .tags {
        list-style: none;
        margin: 14px 0 0 0;
        padding: 0;
        display: flex;

        li {
          background: $mist;
          margin-right: 8px;
          border-radius: 4px;
          border: 1px solid $sleet;
          padding: 3px 5px;
          color: $strom;
          font-size: 12px;
          @include Gilroy-Bold;
          width: auto;
          &.holes {
            color: $ocean;
            border: 1px solid #9be1e4;
            background: #e5f8f8;
          }
        }
      }
      .holes {
        list-style: none;
        margin: 0px 0 0 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;

        &.wrap {
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0;

          li {
            width: calc(5.5555555556%);
            flex: unset;
            box-sizing: border-box;
          }
        }

        li {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-right: 1px solid white;
          margin-top: 15px;
          max-width: 40px;

          &:last-child {
            border-right: 0px solid white;
          }
          .hole-name {
            font-size: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
          }
          .hole-par {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 10px;
            @include Gilroy-Bold;

            padding: 5px 0;

            &.low {
              background-color: $mist;
            }
            &.mid {
              background-color: $sleet;
            }
            &.high {
              background-color: $blizzard;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  main {
    cursor: pointer;
  }
}
</style>
