<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.414"
    height="17.414"
    viewBox="0 0 17.414 17.414"
    :class="[color]"
  >
    <g transform="translate(-24 -75)">
      <g class="a" transform="translate(24 75)">
        <circle class="c" cx="7.333" cy="7.333" r="7.333" />
        <circle class="d" cx="7.333" cy="7.333" r="6.333" />
      </g>
      <line class="b" x2="3" y2="3" transform="translate(37 88)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Search",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.midnight {
  .a,
  .b {
    stroke: #2d323c;
    stroke-width: 2px;
  }
}
.a,
.b,
.d {
  fill: none;
}
.a,
.b {
  stroke: #b4b6c2;
  stroke-width: 2px;
}
.a {
  stroke-miterlimit: 10;
}
.b {
  stroke-linecap: round;
}
.c {
  stroke: none;
}
</style>