<template>
  <svg
    :class="{ color, disabled: disabled == true ? 'disabled' : '' }"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g class="a">
      <circle class="b" cx="14" cy="14" r="14" />
      <circle class="c" cx="14" cy="14" r="13" />
    </g>
    <line class="a" x2="12" transform="translate(8 14)" />
    <line class="a" x2="12" transform="translate(14 8) rotate(90)" />
  </svg>
</template>

<script>
export default {
  name: "Increase",
  props: ["color", "disabled"],
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;

  &.grey {
    .a {
      stroke: #b4b6c2;
    }
    &:hover {
      .a {
        stroke: #2d323c;
        stroke-width: 2px;
      }
    }
  }
  &.disabled {
    .a {
      stroke: #e7e9ec;
    }
    &:hover {
      .a {
        stroke: #e7e9ec;
        stroke-width: 2px;
      }
    }
  }
  .a,
  .c {
    fill: none;
  }
  .a {
    stroke: #b4b6c2;
    stroke-width: 2px;
    transition: all ease 0.3s;
  }
  .b {
    stroke: none;
  }
  &:hover {
    .a {
      stroke: #2d323c;
      stroke-width: 2px;
    }
  }
}
</style>