<template>
  <div class="selector-container">
    <div class="title" :style="{ color: colors.primary }" v-if="title != ''">
      {{ title }}
    </div>
    <div class="selector">
      <span @click.prevent="decreaseValue()">
        <Decrease color="grey" :disabled="valueIndex == 0" />
      </span>
      <div
        class="value"
        :style="{ color: colors.secondary, backgroundColor: colors.primary }"
        :class="{ disabled: disabled }"
      >
        {{ displayedValue.displayValue }}
      </div>
      <span @click.prevent="increaseValue()">
        <Increase color="grey" :disabled="valueIndex + 1 == values.length" />
      </span>
    </div>
    <div class="text" v-if="displayedValue.text != ''">
      {{ displayedValue.text }}
    </div>
  </div>
</template>

<script>
import Increase from "@/assets/icons/Increase";
import Decrease from "@/assets/icons/Decrease";
import { debounce } from "lodash";
/*
  Expects an object with primary and secondary color as well as an array of objects containing the toggleable values including a default property.
  Emits a default event to be listened for.
      {
        title: "",
        value: 3,
        displayValue: 3,
        default: true,
        text: "hour before teeoff"
      },
      {
        primary: "#fff",
        secondary: "#fff"
      }
*/
export default {
  name: "ValueSelector",
  props: ["colors", "values", "title"],
  components: {
    Increase,
    Decrease,
  },
  data() {
    return {
      valueIndex: 1,
    };
  },
  watch: {
    values: function () {
      this.values.forEach((element, index) => {
        if (element.default) {
          this.valueIndex = index;
        }
      });
    },
  },
  computed: {
    disabled() {
      return this.displayedValue.value == 0;
    },
    displayedValue() {
      return this.values[this.valueIndex];
    },
  },
  methods: {
    debouncedEmitValue: debounce(function (event) {
      this.$emit("updatedValue", this.values[this.valueIndex]);
    }, 500),
    increaseValue() {
      if (this.values.length - 1 > this.valueIndex) {
        this.valueIndex++;
        this.debouncedEmitValue();
      }
    },
    decreaseValue() {
      if (this.valueIndex > 0) {
        this.valueIndex--;
        this.debouncedEmitValue();
      }
    },
  },
  beforeMount() {
    this.values.forEach((element, index) => {
      if (element.default) {
        this.valueIndex = index;
      }
    });
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    transition: all ease 0.3s;
    margin: 0;
    opacity: 1;
  }

  .hide {
    opacity: 0.2;
    transition: all ease 0.3s;
  }

  .title {
    font-size: 16px;
    @include Gilroy-Bold;
  }

  button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    top: 1209px;
    left: 592px;
    width: 32px;
    height: 32px;
    border: 1px solid $mouse;
    opacity: 1;
    border-radius: 50%;
    color: $mouse;
    @include Gilroy-Regular;
  }

  .selector {
    display: flex;
    justify-content: center;
    align-items: center;
    .value {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      @include Gilroy-Bold;
      border-radius: 4px;
      margin: 0px 15px;
      transition: all ease 0.3s;
      letter-spacing: 1.1px;

      &.disabled {
        background-color: transparent !important;
        border: 1px solid $fog;
        color: $fog !important;
      }
    }
  }

  .text {
    font-size: 14px;
    @include Gilroy-Bold;
    text-align: center;
    width: 100%;
    margin-top: 8px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .selector-container {
    .selector {
      .value {
        width: 48px;
        height: 48px;
        font-size: 16px;
      }
    }

    .text {
      font-size: 16px;
    }
  }
}
</style>
