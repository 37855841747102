<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 16 16"
  >
    <path
      class="a"
      d="M40-57c0,.674-.983,1.212-1.148,1.834-.172.644.407,1.6.081,2.16s-1.451.553-1.915,1.018-.437,1.581-1.01,1.913-1.523-.245-2.169-.072C33.215-49.979,32.677-49,32-49s-1.215-.979-1.839-1.147c-.646-.173-1.6.4-2.169.072s-.546-1.448-1.011-1.913-1.584-.446-1.914-1.018.253-1.516.081-2.16C24.983-55.788,24-56.326,24-57s.983-1.212,1.148-1.834c.172-.644-.407-1.6-.081-2.16s1.451-.553,1.915-1.018.437-1.581,1.01-1.913,1.523.245,2.169.072C30.785-64.021,31.323-65,32-65s1.215.979,1.839,1.147c.646.173,1.6-.4,2.169-.072s.546,1.448,1.011,1.913,1.584.446,1.914,1.018-.253,1.516-.081,2.16C39.017-58.212,40-57.674,40-57Z"
      transform="translate(-24 65)"
    />
    <path
      class="b"
      d="M34.769-60.077l-3.692,3.6-1.846-1.8L28-57.077l1.846,1.8.615.6.615.6L36-58.877Z"
      transform="translate(-24 65.077)"
    />
  </svg>
</template>

<script>
export default {
  name: "Verified",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #05b5bc;
}
.b {
  fill: #fff;
}
</style>